export class Constants {
    static ACCESS_TOKEN_KEY = 'accessToken';
    static USER_PROFILE_KEY = 'userProfile';
    static CONTENT_TYPE_KEY = 'Content-Type';
    static APPLICATION_JSON = 'application/json';
    static MULTIPART_FORM_DATA = 'multipart/form-data';
    static AUTHORIZATION_KEY = 'authorization';
    static SECURE_PATH = 'secure/';
    static API_PATH_SEPARATOR = '/';

    /*Local Server - START */
    //static BASE_URL = 'http://localhost:8080/easyink/api/'; 
    // static BASE_URL = 'http://192.168.1.240:8080/easyink/'; 
    //static SIGNING_PAGE = 'http://localhost:4200/sign/'; 
    // static SIGNING_PAGE = 'http://192.168.1.240:4200/sign/'; 
    //static TEST_SYS_MSG = 'Attention! This is a Test System. For Production system, please visit the Production site.'; 
    /*Local Server - END */

    /*Test Server - START*/
    //static BASE_URL = 'http://test.iinkit.net/easyink/'; //Commented for PR 1040
    static BASE_URL = 'https://testiinkit.conscisys.com/easyink/'; //PR 1040
    //static SIGNING_PAGE = 'http://test.iinkit.net/sign/';  //Commented for PR 1040
    static SIGNING_PAGE = 'https://testiinkit.conscisys.com/sign/'; //PR 1040
    static TEST_SYS_MSG = 'Attention! This is a Test System. For Production system, please visit the Production site.'; 
    /*Test Server - END*/

    /* Prod Server - START */
    //static BASE_URL = 'http://iinkit.net/easyink/';  //Commented for PR 1040
    //static BASE_URL = 'https://iinkit.conscisys.com/easyink/'; //PR 1040
    //static SIGNING_PAGE = 'http://iinkit.net/sign/'; //Commented for PR 1040
    //static SIGNING_PAGE = 'https://iinkit.conscisys.com/sign/'; //PR 1040
    //static TEST_SYS_MSG = ''; 
    /* Prod Server - END */  
}
